/* CardCollapsePanel */
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  background-color: #f8f5f2;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header > .ant-collapse-extra {
  font-weight: normal;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
  margin-bottom: 1.5em;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 16px;
  padding-bottom: 6px;
}

.ant-tag.ant-tag-red {
  background-color: #fff0f0;
  border-color: #e55f4c;
  color: #e55f4c;
}
.ant-tag.ant-tag-green {
  color: #6a9e98;
  background: #f2fcf8;
  border-color: #6a9e98;
}
