.HeaderLinkText {
  color: #3a3a3a !important;
  margin-right: 15px;
}
.HeaderLinkText:hover {
  color: #e55f4c !important;
}

.ChannelPartnerTagLandingPage {
  background-color: #ffd9a8;
  border-color: #ffc777;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
}
