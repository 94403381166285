.button-row {
  display: flex;
}

.button-row > button:not(:last-child) {
  margin-right: 0.5em;
}

.button-row > i.anticon:not(:last-child) {
  margin-right: 0.3em;
}
