.toolbar {
  background-color: #f8f5f2;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.toolbar .ant-input {
  background: transparent;
  border: none;
  box-shadow: none;
}

.toolbar .ant-select-selection {
  background: transparent;
  border: none;
  box-shadow: none;
  width: 220px;
}

.toolbar .ant-input::placeholder,
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: #cfcfcf;
  opacity: 1;
}
