.select-stack {
  position: auto;
  align-content: center;
  border: 0.1em solid #cfcfcf;
  border-radius: 0.25em;
  width: 222.78px;
  height: min-content;
  margin-right: 16px;
  margin-bottom: 4px;
  display: inline-block;
}

.select-stack > .ant-row.ant-form-item:not(:last-child) {
  margin-right: 0;
  border-bottom: 0.1em solid #cfcfcf;
}

.select-stack > select:not(:last-child) {
  margin-bottom: 0em;
}
