.editPromptForm .ant-form-item {
  /* color: pink; */
  margin-bottom: 5px;
}

.editPromptForm .ant-form-item-label {
  line-height: 30px;
}

.editPromptForm .editPromptOptionsHeader {
  margin-top: 10px;
}

.editPromptForm .editPromptOptions {
  margin-top: 5px;
}
