.prompt-card {
  cursor: default;
}

.prompt-card .ant-typography {
  color: rgba(0, 0, 0, 0.45);
}

.display-help-text {
  margin-top: 24px;
}

.none-prompt-option {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
}
