.persona {
  display: flex;
  align-items: center;
  height: 56px;
}

.persona .persona-text {
  line-height: normal;
  margin-left: 15px;
}

.persona .ant-avatar {
  vertical-align: middle;
}

.persona .persona-primary-text {
}

.persona .persona-secondary-text {
  font-size: 12px;
}

.persona-link {
  color: inherit !important;
}
