.profile-completion {
  padding-bottom: 10px;
  font-size: small;
  width: 100%;
}

.percentage-text {
  padding-top: 5px;
  padding-bottom: 5px;
}
/*red E55F4C ocean 496A86 wintBlue 5CA2D1 yellow E9A867 green 6A9E98*/
