.major-minor-card {
  cursor: default;
}

.major-minor-card .ant-typography {
  color: rgba(0, 0, 0, 0.45);
}

.major-minor-help {
  font-size: 12px;
}