body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body {
  height: 100vh;
}

a {
  color: #e55f4c !important;
}
a:hover {
  color: #3a3a3a !important;
}

.m-0 {
  margin: 0;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.rotate-90 {
  transform: rotate(90deg);
}

.ms-DetailsRow-cell {
  color: rgb(46, 46, 46);
}

/* .ms-Button-label {Color: rgb(236, 236, 236)} */
/* .ms-Button-label2 {Color: rgb(177, 47, 47)} */

.ms-Button-label.label-168 {
  color: rgb(0, 0, 0);
}
.ms-Label.root-128 {
  font-size: 14px;
  font-weight: bold;
}

.persona {
  display: flex;
  align-items: center;
  height: 56px;
}

.persona .persona-text {
  line-height: normal;
  margin-left: 15px;
}

.persona .ant-avatar {
  vertical-align: middle;
}

.persona .persona-primary-text {
}

.persona .persona-secondary-text {
  font-size: 12px;
}

.persona-link {
  color: inherit !important;
}

.ant-layout-header {
  background-color: #f8f5f2;
  box-shadow: rgba(0, 0, 0, 0.08) 0 1px 0;
  margin-bottom: 1px;
  height: 56px;
  line-height: 56px;
}

/* CardCollapsePanel */
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  background-color: #f8f5f2;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header > .ant-collapse-extra {
  font-weight: normal;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
  margin-bottom: 1.5em;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 16px;
  padding-bottom: 6px;
}

.ant-tag.ant-tag-red {
  background-color: #fff0f0;
  border-color: #e55f4c;
  color: #e55f4c;
}
.ant-tag.ant-tag-green {
  color: #6a9e98;
  background: #f2fcf8;
  border-color: #6a9e98;
}

/* Google charts timeline */
rect[fill='#e6e6e6'] {
  fill: #f7f7f7;
}
text[text-anchor='middle']:not([font-weight='bold']) {
  display: none;
}

.prompt-card {
  cursor: default;
}

.prompt-card .ant-typography {
  color: rgba(0, 0, 0, 0.45);
}

.display-help-text {
  margin-top: 24px;
}

.none-prompt-option {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
}

.button-row {
  display: flex;
}

.button-row > button:not(:last-child) {
  margin-right: 0.5em;
}

.button-row > i.anticon:not(:last-child) {
  margin-right: 0.3em;
}

.career-coach-card {
  cursor: default;
}

.career-coach-card .ant-typography {
  color: rgba(0, 0, 0, 0.45);
}

.employee-entity-card {
  cursor: default;
}

.employee-entity-card .ant-typography {
  color: rgba(0, 0, 0, 0.45);
}

.major-minor-card {
  cursor: default;
}

.major-minor-card .ant-typography {
  color: rgba(0, 0, 0, 0.45);
}

.major-minor-help {
  font-size: 12px;
}
.profile-completion {
  padding-bottom: 10px;
  font-size: small;
  width: 100%;
}

.percentage-text {
  padding-top: 5px;
  padding-bottom: 5px;
}
/*red E55F4C ocean 496A86 wintBlue 5CA2D1 yellow E9A867 green 6A9E98*/

.toolbar {
  background-color: #f8f5f2;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.toolbar .ant-input {
  background: transparent;
  border: none;
  box-shadow: none;
}

.toolbar .ant-select-selection {
  background: transparent;
  border: none;
  box-shadow: none;
  width: 220px;
}

.toolbar .ant-input::-webkit-input-placeholder {
  color: #cfcfcf;
  opacity: 1;
}

.toolbar .ant-input::-ms-input-placeholder {
  color: #cfcfcf;
  opacity: 1;
}

.toolbar .ant-input::placeholder,
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: #cfcfcf;
  opacity: 1;
}

/* Google charts timeline */
rect[fill='#e6e6e6'] {
  fill: #f7f7f7;
}
text[text-anchor='middle']:not([font-weight='bold']) {
  display: none;
}

.info-icon {
  margin-left: 4px;
}
.info-icon:hover {
  color: #e55f4c;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0);
}

.HeaderLinkText {
  color: #3a3a3a !important;
  margin-right: 15px;
}
.HeaderLinkText:hover {
  color: #e55f4c !important;
}

.ChannelPartnerTagLandingPage {
  background-color: #ffd9a8;
  border-color: #ffc777;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
}

.editable-text-field:hover {
  cursor: pointer;
  border: 1px solid;
}

.ChannelPartnerTagDetailPage {
  background-color: #ffd9a8;
  border-color: #ffc777;
  padding: 5px 55px;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 20px;
}

.OverflowText_overflowText__nqX9F {
  display: -webkit-box;
  /*-webkit-line-clamp: 3;*/
  /*-webkit-box-orient: vertical;*/
  overflow: hidden;
}

.BadgeStats_statsWrapper__LH55z {
  display: flex;
  justify-content: space-between;
}

.BadgeStats_scrollWrapper__1Xsyr {
  max-height: 291px;
  overflow: scroll;
}

.select-stack {
  position: auto;
  align-content: center;
  border: 0.1em solid #cfcfcf;
  border-radius: 0.25em;
  width: 222.78px;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin-right: 16px;
  margin-bottom: 4px;
  display: inline-block;
}

.select-stack > .ant-row.ant-form-item:not(:last-child) {
  margin-right: 0;
  border-bottom: 0.1em solid #cfcfcf;
}

.select-stack > select:not(:last-child) {
  margin-bottom: 0em;
}

.no-header-collapse .ant-collapse-item .ant-collapse-header {
  padding: 0;
  border: none;
}

.no-header-collapse .ant-collapse-content-box {
  padding: 10px;
}

.editPromptForm .ant-form-item {
  /* color: pink; */
  margin-bottom: 5px;
}

.editPromptForm .ant-form-item-label {
  line-height: 30px;
}

.editPromptForm .editPromptOptionsHeader {
  margin-top: 10px;
}

.editPromptForm .editPromptOptions {
  margin-top: 5px;
}

.drop-over-downward,
tr.drop-over-downward td {
  border-bottom: 2px dashed #e55f4c;
}

.drop-over-upward,
tr.drop-over-upward td {
  border-top: 2px dashed #e55f4c;
}

.drag-and-drop-item {
  cursor: 'move';
  padding: 8px;
}

.drag-and-drop-item:hover {
  background-color: rgba(250, 250, 250);
}

.editTagForm .ant-form-item {
  margin-bottom: 5px;
}

.editTagForm .ant-form-item-label {
  line-height: 30px;
}

.editPracticeForm .ant-form-item {
  margin-bottom: 5px;
}

.editPracticeForm .ant-form-item-label {
  line-height: 30px;
}

.editEmployeeBusinessUnitForm .ant-form-item {
  margin-bottom: 5px;
}

.editEmployeeBusinessUnitForm .ant-form-item-label {
  line-height: 30px;
}

.editRegionForm .ant-form-item {
    margin-bottom: 5px;
}

.editRegionForm .ant-form-item-label {
    line-height: 30px;
}
.editLocationForm .ant-form-item {
    margin-bottom: 5px;
}

.editLocationForm .ant-form-item-label {
    line-height: 30px;
}
.editRoleTypeFamilyForm .ant-form-item {
  margin-bottom: 5px;
}

.editRoleTypeFamilyForm .ant-form-item-label {
  line-height: 30px;
}

.ant-layout {
  background-color: transparent;
}

.ant-form-vertical .ant-form-item {
  padding-bottom: 2px;
  margin-bottom: 8px;
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 2px;
}

/* Rich Text Editor */
.public-DraftEditor-content {
  min-height: 110px;
}

.ant-card-meta-title {
  white-space: normal;
}

.ant-card {
  display: flex;
  height: 100%;
}

.ant-card-body {
  width: 100%;
  height: 100%;
}

/* Overwrite Ant styles multiselect dropdown so checkmark shows on left instead of right */
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-left: 32px;
  padding-right: 0;
  padding-right: initial;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
  left: 12px;
  right: auto;
  right: initial;
}

/* Style card actions */
.ant-card .ant-card-actions {
  border-top: none;
  background-color: #FFFFFF;
}

.ant-card .ant-card-actions button {
  color: #e55f4c;
}

/* Style cards with images */
.avatar-card {
  height: 100%;
}
.avatar-card .ant-card-meta-avatar {
  float: right;
  padding-right: 0;
  padding-left: 16px;
}
.avatar-card .ant-avatar-lg {
  width: 60px;
  height: inherit;
}

.filter-bar-select-dropdown.ant-select-dropdown {
  padding: 8px 0;
  border-radius: 4px;
}
.filter-bar-select .ant-select-arrow {
  cursor: pointer;
}

.key-metrics-select .ant-select-selection {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

/* Ant DatePicker */
.ant-calendar-month-panel-month {
  color: #3a3a3a !important;
}

.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: #fff !important;
}

.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month {
  color: rgba(0, 0, 0, 0.25) !important;
}

/* Ant Tables */
.ant-table .ant-table-row.hide-expand .ant-table-row-expand-icon {
  display: none;
}

/* Ant Alerts */
.ant-alert-info {
  background-color: #d7ecf3;
}

.ant-alert-info .ant-alert-icon {
  color: #5ca2d1;
}

.rich-text-editor {
  font-family: inherit;
}

