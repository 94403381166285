.ant-layout {
  background-color: transparent;
}

.ant-form-vertical .ant-form-item {
  padding-bottom: 2px;
  margin-bottom: 8px;
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 2px;
}

/* Rich Text Editor */
.public-DraftEditor-content {
  min-height: 110px;
}

.ant-card-meta-title {
  white-space: normal;
}

.ant-card {
  display: flex;
  height: 100%;
}

.ant-card-body {
  width: 100%;
  height: 100%;
}

/* Overwrite Ant styles multiselect dropdown so checkmark shows on left instead of right */
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-left: 32px;
  padding-right: initial;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
  left: 12px;
  right: initial;
}

/* Style card actions */
.ant-card .ant-card-actions {
  border-top: none;
  background-color: #FFFFFF;
}

.ant-card .ant-card-actions button {
  color: #e55f4c;
}

/* Style cards with images */
.avatar-card {
  height: 100%;
}
.avatar-card .ant-card-meta-avatar {
  float: right;
  padding-right: 0;
  padding-left: 16px;
}
.avatar-card .ant-avatar-lg {
  width: 60px;
  height: inherit;
}

.filter-bar-select-dropdown.ant-select-dropdown {
  padding: 8px 0;
  border-radius: 4px;
}
.filter-bar-select .ant-select-arrow {
  cursor: pointer;
}

.key-metrics-select .ant-select-selection {
  width: max-content;
}

/* Ant DatePicker */
.ant-calendar-month-panel-month {
  color: #3a3a3a !important;
}

.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: #fff !important;
}

.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month {
  color: rgba(0, 0, 0, 0.25) !important;
}

/* Ant Tables */
.ant-table .ant-table-row.hide-expand .ant-table-row-expand-icon {
  display: none;
}

/* Ant Alerts */
.ant-alert-info {
  background-color: #d7ecf3;
}

.ant-alert-info .ant-alert-icon {
  color: #5ca2d1;
}

.rich-text-editor {
  font-family: inherit;
}
