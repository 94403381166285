.drop-over-downward,
tr.drop-over-downward td {
  border-bottom: 2px dashed #e55f4c;
}

.drop-over-upward,
tr.drop-over-upward td {
  border-top: 2px dashed #e55f4c;
}

.drag-and-drop-item {
  cursor: 'move';
  padding: 8px;
}

.drag-and-drop-item:hover {
  background-color: rgba(250, 250, 250);
}
