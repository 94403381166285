.m-0 {
  margin: 0;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.rotate-90 {
  transform: rotate(90deg);
}

.ms-DetailsRow-cell {
  color: rgb(46, 46, 46);
}

/* .ms-Button-label {Color: rgb(236, 236, 236)} */
/* .ms-Button-label2 {Color: rgb(177, 47, 47)} */

.ms-Button-label.label-168 {
  color: rgb(0, 0, 0);
}
.ms-Label.root-128 {
  font-size: 14px;
  font-weight: bold;
}
